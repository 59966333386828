import { createStyles, makeStyles } from '@material-ui/core/styles'

import courseBg from '../../assets/course-card.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 24,
      background: `url(${courseBg}) no-repeat center`,
      backgroundSize: 'cover',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20
    },
    flex: {
      display: 'flex'
    },
    info: {
      width: '100%'
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1,
      textDecoration: 'none',
      color: theme.palette.text.primary
    },
    author: {
      fontSize: 14,
      marginTop: 10,
      marginBottom: 16,
      lineHeight: 1
    },
    progress: {
      fontSize: 14,
      marginTop: 6,
      marginBottom: 16,
      color: '#546E7A',
      lineHeight: 1
    }
  })
)

export default useStyles
