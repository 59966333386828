import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import Link from 'next/link'
import {
  Button,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress as MUILinearProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useAuth } from '../../lib/AuthContext'

import useStyles from './styles'

const LinearProgress = withStyles(() => ({
  root: {
    height: 6,
    borderRadius: 20,
    backgroundColor: '#455A6440'
  },
  bar: {
    borderRadius: 20
  }
}))(MUILinearProgress)

const CourseCard = ({
  title,
  courseId,
  courseVersionId,
  defaultVersion,
  progress
}) => {
  const classes = useStyles()
  const {
    user: { role }
  } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <div className={classes.info}>
          <Link
            href="/app/courses/[courseId]/version/[courseVersionId]"
            as={`/app/courses/${courseId}/version/${courseVersionId || defaultVersion
              }`}
            passHref
          >
            <a className={classes.title}>{title}</a>
          </Link>
          <Typography className={classes.author}>Por Túlio Faria</Typography>
          {role === 'user' && (
            <>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={progress && progress.toFixed()}
              />
              <Typography className={classes.progress}>
                {`Seu progresso: ${progress && progress.toFixed()}% concluído`}
              </Typography>
            </>
          )}
        </div>
      </div>
      <Grid
        container
        justify={role !== 'owner' ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {role !== 'owner' && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              Router.push(
                '/app/courses/[courseId]/version/[courseVersionId]',
                `/app/courses/${courseId}/version/${courseVersionId || defaultVersion
                }`
              )
            }
          >
            Retomar Curso
          </Button>
        )}
        {role === 'owner' && (
          <>
            <IconButton size="medium" onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() =>
                  Router.push(
                    '/app/courses/[courseId]/content/[courseVersionId]',
                    `/app/courses/${courseId}/content/${defaultVersion}`
                  )
                }
              >
                Editar Conteúdo
              </MenuItem>
              <MenuItem disabled>Criar Oferta</MenuItem>
            </Menu>
          </>
        )}
      </Grid>
    </div>
  )
}

CourseCard.propTypes = {
  title: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  courseVersionId: PropTypes.string.isRequired,
  defaultVersion: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired
}

export default CourseCard
